<template>
    <div class="bg-page" style="min-height: 300px">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('我的银行卡')"
                     left-arrow
                     @click-left="onClickLeft"
        >
            
        </van-nav-bar>
        <div class="bank-list" v-if="list.length > 0">
            <div class="bank-card" v-for="(item, index) in list" :class="`bank-card card-` + ((index % 2) + 1)"
                 :key="index"
            >
                <template v-if="item.type == 0">
                    <div class="bank-head">
                        <!-- <div class="bank-tt">{{$t('Card no.')}}</div> -->
                        <div class="bank-tt">{{item.bank_name}}</div>
                    </div>
                    <div class="bank-body" style="font-size: 24px">
                        <div class="bank-number">
                            {{item.number.replace(/^(\w{4})\w+(\w{4})$/,"$1 **** **** $2")}}
                        </div>
                        <!-- <div class="bank-tag">
                            <div class="c-name">{{item.bank_name}}</div>
                        </div> -->
                    </div>
                </template>
                <template v-if="item.type == 1">
                    <div class="bank-head">
                        <!-- <div class="bank-tt">{{$t('Wallet Address')}}</div> -->
                        <div class="bank-tt">{{item.bank_name}}</div>
                    </div>
                    <div class="bank-body" style="font-size: 24px">
                        <div class="bank-number">
                            {{item.number.replace(/^(\w{4})\w+(\w{4})$/,"$1 **** **** $2")}}
                        </div>
                        <!-- <div class="bank-tag">
                            <div class="c-name">{{item.bank_name}}</div>
                        </div> -->
                    </div>
                </template>
            </div>
        </div>
        <div class="plus-lg" @click="onClickRight" v-if="list.length < this.length">
            <van-icon name="plus" color="#a3b6bd" size="50"/>
        </div>
        <van-popup class="page-popup" v-model="show2" position="bottom" closeable
                   :style="{ borderRadius: '10px'}">
            <div class="wt-dialog">
                <div class="wt-top">{{$t('选择取款方式')}}</div>
                <div class="wt-panel">
                    <van-radio-group v-model="add_radio">
                        <van-cell v-if="usdt == '1'" center :title="$t('USDT（TRC20）')"
                                  :label="$t('强烈推荐TRC20，免手续费')" clickable
                                  @click="add_radio = 'TRC20'">
                            <template #icon>
                                <img src="../../assets/pictures/USDT.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="TRC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <!-- <van-cell v-if="usdt == '1'" center :title="$t('USDT（ERC20）')"
                                  clickable
                                  @click="add_radio = 'ERC20'">
                            <template #icon>
                                <img src="../../assets/pictures/USDT.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="ERC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell center :title="$t('银行卡')" clickable @click="add_radio = 'BANK'">
                            <template #icon>
                                <img src="../../assets/pictures/bank-icon.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="BANK">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell> -->
                    </van-radio-group>
                    <div class="popbtn">
                        <van-row :gutter="10">
                            <van-col :span='12'><van-button  type="gray" class="nfobtn" size="normal" @click="show2 = false">{{$t('取消')}}</van-button></van-col>
                            <van-col :span='12'><van-button type="primary" size="normal" @click="toBind">{{$t('确定')}}</van-button></van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                show: false,
                show2: false,
                add_radio: 'TRC20',
                usdt: false,
                activeIcon: require('../../assets/pictures/duigouxiao.png'),
                inactiveIcon: require('../../assets/pictures/duigouxiao2.png'),
                list: [],
                columns:[],
                length: 0
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1);
            },
            onClickRight() {
                if (this.usdt) {
                    this.show2 = true
                } else {
                    this.$router.push({path: '/bank-bind'})
                }
            },
            toBind() {
                if (this.add_radio == 'BANK') {
                    this.$router.push({path: '/bank-bind',});
                } else if (this.add_radio == 'PIX') {
                    this.$router.push({path: '/pix-bind',});
                } else {
                    this.$router.push({path: '/bind-wallet', query: {channel: this.add_radio}});
                }
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?tag=list').then((res) => {
                    this.list = res.data.data.info;
                    this.usdt = res.data.data.usdt;
                    this.columns = res.data.data.list;
                    this.length = res.data.data.list.length
                    if(this.usdt == 1){
                        this.length += 2
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            selectBank(item) {
                if (item.type == 1) {
                    this.$router.push({path: '/bind-wallet', query: {id: item.id}});
                } else if (item.type == 2) {
                    this.$router.push({path: '/pix-bind', query: {id: item.id}});
                } else if (item.type == 0) {
                    this.$router.push({path: '/bank-bind', query: {id: item.id}});
                }
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>
<style>
    .plus-lg{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 20px;
        border-radius: 10px;
        height: 140px;
        background-color: #394d58;
    }



</style>